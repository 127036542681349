<template>
    <div class="container mar-top mb-5">
    <div class="row">
        <div class="col-lg-3 col-md-6">
            <h4>Categories</h4>
            <ul class="list-inline footer-text">
                <li class="mt-4"><a href="javascript:void(0)">Graphics & Design</a></li>
                <li class="my-3"><a href="javascript:void(0)">Digital Marketing</a></li>
                <li class="my-3"><a href="javascript:void(0)">Writing & Translation</a></li>
                <li class="my-3"><a href="javascript:void(0)">Video & Animation</a></li>
                <li class="my-3"><a href="javascript:void(0)">Music & Animation</a></li>
                <li class="my-3"><a href="javascript:void(0)">Programming & Tech</a></li>
                <li class="my-3"><a href="javascript:void(0)">Business</a></li>
            </ul>
        </div>
        <div class="col-lg-3  col-md-6">
            <h4>Our Community</h4>
            <ul class="list-inline  footer-text">
                <li class="mt-4"><a href="javascript:void(0)">Events</a></li>
                <li class="my-3"><a href="javascript:void(0)">Forum</a></li>
                <li class="my-3"><a href="javascript:void(0)">Community Standards</a></li>
                <li class="my-3"><a href="javascript:void(0)">Podcast</a></li>
                <li class="my-3"><a href="javascript:void(0)">Affiliates</a></li>
                <li class="my-3"><a href="javascript:void(0)">Invite a Friend</a></li>
                <li class="my-3"><a href="javascript:void(0)">Become a Selle</a></li>
            </ul>
        </div>
        <div class="col-lg-3  col-md-6">
            <h4>Support</h4>
            <ul class="list-inline footer-text">
                <li class="mt-4"><a href="javascript:void(0)">Help & Support</a></li>
                <li class="my-3"><a href="javascript:void(0)">Trust & Safety</a></li>
                <li class="my-3"><a href="javascript:void(0)">Selling on Fiverr</a></li>
                <li class="my-3"><a href="javascript:void(0)">Buying on Fiverr</a></li>
            </ul>
        </div>
        <div class="col-lg-3  col-md-6">
            <h4>About</h4>
            <ul class="list-inline footer-text">
                <li class="mt-4"><a href="javascript:void(0)">Careers</a></li>
                <li class="my-3"><a href="javascript:void(0)">Press & News</a></li>
                <li class="my-3"><a href="javascript:void(0)">Partnerships</a></li>
                <li class="my-3"><a href="javascript:void(0)">Privacy Policy</a></li>
                <li class="my-3"><a href="javascript:void(0)">Terms of Service</a></li>
                <li class="my-3"><a href="javascript:void(0)">Intellectual Property</a></li>
                <li class="my-3"><a href="javascript:void(0)">Claims</a></li>
                <li class="my-3"><a href="javascript:void(0)">Investor Relations</a></li>
            </ul>
        </div>
    </div>
</div>
</template>
<script>
export default {
  name: 'landingfooter'
}
</script>
