<template>
      <div class="auth-shap position-relative">
        <svg class="rect1" width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="11.0215" width="14" height="14" rx="2" transform="rotate(-51.9256 0 11.0215)" fill="#FDDA5F"/>
        </svg>
        <svg class="rect2" width="11" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="5.7207" width="8" height="8" rx="2" transform="rotate(-51.9256 0 5.7207)" fill="#FDDA5F"/>
        </svg>
        <svg class="rect3" width="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="7.48828" width="10" height="10" rx="2" transform="rotate(-51.9256 0 7.48828)" fill="#18995B"/>
        </svg>
        <svg class="rect4" width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="11.0195" width="14" height="14" rx="2" transform="rotate(-51.9256 0 11.0195)" fill="#FFE0E3"/>
        </svg>
        <svg class="rect5" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="14.377" width="18.2858" height="18.2858" rx="2" transform="rotate(-51.9256 0 14.377)" fill="#FAD1D1"/>
        </svg>
        <svg class="rect6" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="6.78516" width="9.51587" height="9.51587" rx="2" transform="rotate(-51.9256 0 6.78516)" fill="#18995B"/>
        </svg>
        <svg class="rect7" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="11.0195" width="14" height="14" rx="2" transform="rotate(-51.9256 0 11.0195)" fill="#FDDA5F"/>
        </svg>
        <svg class="rect8" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="9.25195" width="12" height="12" rx="2" transform="rotate(-51.9256 0 9.25195)" fill="#FDDA5F"/>
        </svg>
  </div>
</template>
<script>
export default {
  name: 'ShapesIcon'
}
</script>
